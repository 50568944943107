/**
 * @file 全局配置文件
 * @author 郄晓晖
 * */

module.exports = {
  // 根域名
  BASE_URL: 'https://wukaapi.fzked.com',
  // 请求Oss信息的接口
  getOssInfo: 'https://wukaapi.fzked.com/oss/getOssTst',
  OSS: 'oss-cn-hangzhou.aliyuncs.com',
  // Oss bucket的名称
  bucket: 'wkty-sy',
  // 接口地图
  requestMap: {
    Common: {
      editPassWord: '/index/editPsd',
      loginOut: '/index/out',
      login: '/index/accountLogin',
      // 获取分类信息
      getCategory: '/class/getAllList',
      // 获取父母信息
      getParent: '/child/getParent',
      // 获取教练
      getCoach: '/coach/getCoach',
      // 获取用户信息
      getUser: '/api/place/getUsers',
      // 获取产地信息,
      getPlace: '/api/place/getPlaces',
      // 获取省市区
      getCity: '/store/getCity',
      // 获取门店列表
      getStore: '/coach/getStore',
      // 获取私教课类型
      getCourseType: '/course/getPrivateType',
      // 获取课程信息
      getCourse: '/arrange/getCourse',
      // 获取报名的用户
      getArrangeUser: '/divide/getArrangeUser',
      // 获取排课信息
      getArrange: '/divide/getArrange',
      // 获取分销用户
      getDistributeUser: '/distributionUser/getUserList',
      // 获取顶级分销商
      getTopDistributeUser: '/distributionUser/getTopDisUsers',
      // 获取所有权限列表
      getAuthList: '/auth/GetList',
      // 获取所有角色信息
      getRoleList: '/role/getRoleName',
      getPlaceTime: '/time/getTimeInfo',
      getPlaceType: '/placeType/getPlaceTypeInfo',
      getCourseSelf: '/courseSelf/getCourseSelf',
      ossUpload: '/api/index/ossupload',
      //获取球队
      getRankTeam: '/rankTeam/getRankTeam',
      getRankType: '/rankType/getRankType',
      getRankGroup: '/rankGroup/getRankGroup'
    },
    Student: {
      inquire: '/user/studentList',
      change: '/user/operateUser',
      delete: '/user/delUser',
      getStore: '/store/getStore',
      getClass: '/divide/getClassList',
      getCard: '/card/getCardList',
      buyCard: '/order/createCardOrder',
      getOwnerCard: '/cardUser/getCardUserList',
      getOwnerOrder: '/order/getCardOrder',
      getClassList: '/deductCard/attendClassList',
      getSale: '/employee/getSalerList',
      export: '/user/exportStudent',
      import: '/user/importStudent',
      importTemplate: '/user/importFile'
    },
    DivideStudent: {
      inquire: '/divide/getClassUser',
      getAll: '/user/getStudentList',
      add: '/divideUser/addStudent',
      delete: '/divideUser/delDivideUser',
      export: '/divideUser/exportStudent',
      check: '/divideUser/operateDivideUser'
    },
    User: {
      inquire: '/user/getUserList'
    },
    Article: {
      inquire: '/article/getlist',
      change: '/article/operatearticle',
      delete: '/article/delarticle',
      detail: '/article/getDetail'
    },
    Banner: {
      inquire: '/banner/getlist',
      change: '/banner/operatebanner',
      delete: '/banner/delbanner'
    },
    Category: {
      inquire: '/class/getlist',
      change: '/class/operateClass',
      delete: '/class/delClass'
    },
    Place: {
      inquire: '/place/getlist',
      change: '/place/operatePlace',
      delete: '/place/delPlace'
    },
    PlaceOrder: {
      inquire: '/placeOrder/getlist',
      change: '/placeOrder/operatePlaceOrder',
      delete: '/placeOrder/delPlaceOrder'
    },
    DistributionRules: {
      inquire: '/distribution/getDistributionList',
      change: '/distribution/operationRule'
    },
    DistributionUser: {
      inquire: '/distributionUser/getList',
      change: '/distributionUser/operateDistributionUser',
      delete: '/distributionUser/delDistributionUser',
      auditing: ''
    },
    DistributionWithdraw: {
      inquire: '/distribution/disAuditingList',
      change: '/distribution/auditingOperate',
      delete: '/distribution/delWithdrawal'
    },
    DistributionOrder: {
      inquire: '/distribution/getDisList'
    },
    Store: {
      inquire: '/store/getlist',
      change: '/store/operateStore',
      delete: '/store/delStore'
    },
    Coach: {
      inquire: '/coach/getlist',
      change: '/coach/operateCoach',
      delete: '/coach/delCoach'
    },
    Arrange: {
      inquire: '/arrange/getlist',
      change: '/arrange/operateArrange',
      delete: '/arrange/delArrange'
    },
    Course: {
      inquire: '/course/getlist',
      change: '/course/operateCourse',
      delete: '/course/delCourse',
      getCoach: '/coach/getCoach'
    },
    Divide: {
      inquire: '/divide/getList',
      change: '/divide/operateDivide',
      delete: '/divide/delDivide',
      getInfo: '/divide/getInfoByCourse'
    },
    Goods: {
      inquire: '/goods/getlist',
      change: '/goods/operateGoods',
      delete: '/goods/delGoods'
    },
    Child: {
      inquire: '/child/getlist',
      change: '/child/operateChild',
      delete: '/child/delChild'
    },
    Coupon: {
      inquire: '/coupon/getList',
      change: '/coupon/operateCoupon',
      delete: '/coupon/delCoupon',
      sendAll: '/couponTask/operateCouponTask'
    },
    Role: {
      inquire: '/role/getlist',
      change: '/role/operateRole',
      delete: '/role/delRole',
      changeAuth: '/roleAuth/operateRoleAuth',
      getRoleAuth: '/role/getDetails'
    },
    RoleAuth: {
      // inquire: '/roleAuth/getlist',

      // delete: '/roleAuth/delRoleAuth'
    },
    Employee: {
      inquire: '/employee/getList',
      change: '/employee/operateEmployee',
      delete: '/employee/delEmployee'
    },
    PrivateType: {
      inquire: '/privateType/getList',
      change: '/privateType/operatePrivateType',
      delete: '/privateType/delPrivateType'
    },
    PrivateTime: {
      inquire: '/courseSelf/getList',
      change: '/courseSelf/operateCourseSelf',
      delete: '/courseSelf/delCourseSelf'
    },
    PriceType: {
      inquire: '/priceType/getList',
      change: '/priceType/operatePriceType',
      delete: '/priceType/delPriceType'
    },
    Order: {
      inquire: '/order/getList',
      delete: '/order/delOrder',
      audit: '/order/orderRefundAudit',
      exportExcel: '/order/exportOrder'
    },
    Ask: {
      inquire: '/question/getList',
      change: '/question/operateQuestion',
      delete: '/question/delQuestion'
    },
    PlaceTime: {
      inquire: '/time/getList',
      change: '/time/operateTime',
      delete: '/time/delTime'
    },
    LockTime: {
      inquire: '/placeTime/getList',
      change: '/placeTime/operatePlaceTime',
      delete: '/placeTime/delPlaceTime'
    },
    PlaceType: {
      inquire: '/placeType/getList',
      change: '/placeType/operatePlaceType',
      delete: '/placeType/delPlaceType'
    },
    Card: {
      inquire: '/card/getList',
      change: '/card/operateCard',
      delete: '/card/delCard',
      getCourse: '/card/getCourse'
    },
    RankTeam: {
      inquire: '/rankTeam/getList',
      change: '/rankTeam/operateRankTeam',
      delete: '/rankTeam/delRankTeam',
    },
    RankType: {
      inquire: '/rankType/getList',
      change: '/rankType/operateRankType',
      delete: '/rankType/delRankType'
    },
    RankGroup: {
      inquire: '/rankGroup/getList',
      change: '/rankGroup/operateRankGroup',
      delete: '/rankGroup/delRankGroup'
    },
    Rank: {
      inquire: '/rank/getList',
      change: '/rank/operateRank',
      delete: '/rank/delRank'
    },
    QrCode: {
      inquire: '/genericCode/getList',
      refresh: '/genericCode/refreshQrCode',
      change: '/genericCode/operateGenericCode'
    },
    About: {
      inquire: '/about/getList',
      change: '/about/operateAbout',
      delete: '/about/delAbout'
    }
  },
  // 腾讯地图开发者KEY
  mapApiKey: 'BYKBZ-BQ3KX-DO34N-T257Z-TFKS5-2LF77'
}