/**
 * @Author: 陈诚
 * @Date: 2020-05-23 15:10:15
 * @LastEditTime: 2020-06-02 18:43:40
 * @LastEditors: 陈诚
 * @Description: oss 上传组件
 */
// import axios from 'axios'
// import { Message, MessageBox } from 'element-ui'
// import Cookies from 'js-cookie'
import OSS from 'ali-oss';
import SparkMD5 from 'spark-md5';

const pointerCache = {
  get(file, name) {
    const cache = localStorage.getItem('cacheKey')
    if (!cache) return null
    const cacheJson = JSON.parse(cache)
    if (cacheJson.name !== name) return null
    cacheJson.file = file
    return cacheJson
  },
  set(pointer) {
    localStorage.setItem('cacheKey', JSON.stringify(pointer))
  },
  remove() {
    localStorage.removeItem('cacheKey')
  }
}
// 初始化oss类
export default class ossUpload {
  //oss 上传存储的参数
  ossfiles=[];
  //oss 上传位置
  client = null;
  // 构造oss
  constructor(arg) {
    // this.client = new OSS({...arg})
    if( arg && arg.accessKeyId && arg.accessKeySecret) {
      this.client = new OSS({...arg});
    }
    else return false
  };
};

/**
 * @description: 添加上传列表 - 单个oss的文件
 * @param {*} dataSource      数据源    Object
 */

ossUpload.prototype.setOssFile = async function (dataSource) {
  const file = dataSource
  const name = await this.getName(file)
  if (!this.ossfiles.some(item => item.name === name)) {
    this.ossfiles.push({file,percent: 0,name})
  }
};

/**
 * @description: 添加上传列表 - 单个oss的文件
 * @return {*} files        上传列表 Array
 */
ossUpload.prototype.getOssFiles =  function() {
  return this.ossfiles
};

/**
 * @description: 删除上传列表-单个oss的文件
 * @param {*} dataSource      数据源    Object
 */
ossUpload.prototype.delOssFile = async function(dataSource) {
  if(!dataSource) return false
  const name = await this.getName(dataSource)
  const files = this.ossfiles.filter(item => ! item.name === name)
  this.ossfiles = [... files]
};

/**
 * @description: 清空Oss文件列表
 */
ossUpload.prototype.clearOssFiles = async function() {
  const ar = []
  this.ossfiles = [...ar]
};


//上传所有文件
ossUpload.prototype.uploadAll  = async function() {
  if (this.ossfiles.length === 0) {
    // Toast('请选择文件')
    return
  }
  for (let i = 0; i < this.ossfiles.length; i++) {
    const { file, name } = this.ossfiles[i]
    await this.upload(file, name, i)
  }
};
//断点续传
ossUpload.prototype.upload = async function(file, name, index) {
  const that = this
  let checkpoint = pointerCache.get(file, name) // 断点续传指针
  // 失败后尝试两次
  for (let i = 0; i < 2; i++) {
    try {
      const result = await this.client.multipartUpload(name, file, {
        checkpoint,
        async progress(percentage, cpt) { // 上传进度
          that.ossfiles[index].percent = parseInt(percentage * 100, 10)
          pointerCache.set(cpt)
          checkpoint = cpt
        },
      })
      pointerCache.remove()
      that.ossfiles[index].res =  result.res
      break // break if success
    } catch (e) {
      console.log('上传失败：', e)
    }
  }
};


//get文件MD5名；
ossUpload.prototype.getName = async function(file) {
  return new Promise(resolve =>{
    const { name } = file
    const suffix = name.substr(name.lastIndexOf('.')) //文件后缀
    const spark = new SparkMD5.ArrayBuffer()
    const reader = new FileReader()
    reader.readAsArrayBuffer(file)
    // Indicator.open() // loading 文件过大的时候读取时间非常长
    reader.addEventListener('load', (e) => {
      spark.append(e.target.result);
      // Indicator.close()
      resolve(spark.end() + suffix) // 文件md5加密，防止重复上传
    })
  })
};







