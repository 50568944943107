import {request} from '@/utils/utils';
//引入封装oss类
import ossUpload from '@/utils/ossUpload';
// 全局引入配置文件信息
const config = require('../config');
// 定义初始变量
let defaultStore = {
  ...config
}
request('GET', config.getOssInfo).then(res => {
  const {Tst} = res;
  const ossInfo = {
    accessKeyId: Tst.AccessKeyId,
    accessKeySecret: Tst.AccessKeySecret,
    stsToken: Tst.SecurityToken,
    bucket: config.bucket
  }
  defaultStore.ossInfo = {
    ...ossInfo
  }
  defaultStore.ossClass = new ossUpload(ossInfo);
})

// 全局存入oss变量&挂载oss类

export default (state = defaultStore, action) => {
  switch (action.type) {
    default:
      return state
  }
}
