import {request} from '@/utils/utils';
const config = require('@/config');
const {requestMap} = config;

let dict = {};

request('GET', requestMap.Common.getPlace).then(res => {
  dict.placeList = res ? res : [];
})
request('GET', requestMap.Common.getUser).then(res => {
  dict.userList = res ? res : [];
})
request('GET', requestMap.Common.getCity).then(res => {
  dict.cityList = res.list
})
request('GET', requestMap.Common.getStore).then(res => {
  dict.storeList = res.list
})
request('GET', requestMap.Common.getCourseType).then(res => {
  dict.coursePrivateTypeList = res.list
})
request('GET', requestMap.Common.getCoach).then(res => {
  dict.coachList = res.list
})
request('GET', requestMap.Common.getParent).then(res => {
  dict.parentList = res.list
})
request('GET', requestMap.Common.getCourse).then(res => {
  dict.courseList = res.list
})
request('GET', requestMap.Common.getArrange).then(res => {
  dict.arrangeList = res.list
})
request('GET', requestMap.Common.getDistributeUser).then(res => {
  dict.distributeUserList = res ? res : []
})
request('GET', requestMap.Common.getTopDistributeUser).then(res => {
  dict.topDistributeUserList = res ? res : []
})
request('GET', requestMap.Common.getAuthList).then(res => {
  dict.authList = res.list;
})
request('GET', requestMap.Common.getRoleList).then(res => {
  dict.roleList = res.list;
})
request('GET', requestMap.Common.getPlaceTime).then(res => {
  dict.timeList = res.list;
})
request('GET', requestMap.Common.getPlaceType).then(res => {
  dict.placeType = res.list;
})
request('GET', requestMap.Common.getCourseSelf).then(res => {
  dict.courseSelfList = res;
})

request('GET', requestMap.Common.getRankGroup).then(res => {
  dict.rankGroupList = res;
})
request('GET', requestMap.Common.getRankType).then(res => {
  dict.rankTypeList = res;
})
request('GET', requestMap.Common.getRankTeam).then(res => {
  dict.rankTeamList = res;
})
// 场地费用类型
dict.priceTypeList = [{
  id: 'time_price',
  name: '时租定金'
},{
  id: 'day_price',
  name: '日租定金'
},{
  id: 'month_price',
  name: '月租定金'
}];
// 支付状态
dict.payStatusList = [{
  id: '0',
  name: '未支付'
},{
  id: '1',
  name: '已支付'
}];
// 分销返佣类型
dict.distributionTypeList = [{
  id: '0',
  name: '固定'
},{
  id: '1',
  name: '百分比'
}];

dict.distributionLevelList = [{
  id: '1',
  name: '一级'
},{
  id: '2',
  name: '二级'
}];
dict.distributionStatusList = [{
  id: '0',
  name: '待审核'
},{
  id: '1',
  name: '审核通过'
},{
  id: '2',
  name: '驳回'
}];

dict.distributionAuditing = [{
  id: '0',
  name: '审核通过'
},{
  id: '1',
  name: '驳回'
}];
// 性别列表
dict.sexList = [{
  id: '0',
  name: '男'
},{
  id: '1',
  name: '女'
}]
// 课程类型
dict.courseTypeList = [{
  id: '1',
  name: '常规课'
},{
  id: '2',
  name: '私教课'
}]
// 商品上架状态
dict.groundingList = [{
  id: '0',
  name: '未上架'
},{
  id: '1',
  name: '已上架'
}]
// 商品上架状态
dict.attributeStatusList = [{
  id: '0',
  name: '关闭'
},{
  id: '1',
  name: '开启'
}]
// 优惠卷类型
dict.couponTypeList = [{
  id: '0',
  name: '满减券'
},{
  id: '1',
  name: '折扣券'
}]
// 是否类型
dict.recommendList = [{
  id: '0',
  name: '否'
},{
  id: '1',
  name: '是'
}]

dict.askStatus = [{
  id: '0',
  name: '未处理'
},{
  id: '1',
  name: '已处理'
}];

dict.askType = [{
  id: '1',
  name: '场地预约'
},{
  id: '2',
  name: '课程预约'
},{
  id: '3',
  name: '周边购买'
},{
  id: '4',
  name: '其他'
}];

dict.weekList = [{
  id: '1',
  name: '星期一'
},{
  id: '2',
  name: '星期二'
},{
  id: '3',
  name: '星期三'
},{
  id: '4',
  name: '星期四'
},{
  id: '5',
  name: '星期五'
},{
  id: '6',
  name: '星期六'
},{
  id: '7',
  name: '星期天'
}];
dict.cardType = [{
  id: '1',
  name: '常规课会员卡'
},{
  id: '2',
  name: '私教课会员卡'
}]
dict.userType = [{
  id: '0',
  name: '普通用户'
},{
  id: '1',
  name: '学员'
}]
dict.payType = [{
  id: '1',
  name: '微信'
},{
  id: '2',
  name: '支付宝'
},{
  id: '4',
  name: '刷卡'
},{
  id: '5',
  name: '现金'
},{
  id: '6',
  name: 'POS机'
}]
dict.classType = [{
  id: '1',
  name: '常规课排班'
},{
  id: '2',
  name: '私教课排班'
}]
dict.isStopList = [{
  id: '0',
  name: '不停课'
},{
  id: '1',
  name: '停课'
}]
dict.placeTimeType = [{
  id: '1',
  name: '白场时段'
},{
  id: '2',
  name: '夜场时段'
}]
export default (state = dict, action) => {
  switch (action.type) {
    default:
      return state
  }
}

